export const routesWithNoToken = new Set([
  'sign-in',
  'sign-up',
  'forgot-password',
  'password-sent',
  'unsubscribe',
  'activate-account',
]);

export enum AppRoutes {
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  SignUpPersonalDetails = '/sign-up/personal-details',
  SignUpProfessionalDetails = '/sign-up/professional-details',
  SignUpInterests = '/sign-up/interests',
  SignUpAccountCreated = '/sign-up/account-created',
  SignUpWelcome = '/sign-up/welcome',
  ForgotPassword = '/app/forgot-password',
  PasswordSent = '/password-sent',
  KanbanBoard = '/kanban-board',
  ExploreForYou = '/explore/for-you',
  ExploreCoursesAndPathways = '/explore/courses-and-pathways',
  ExploreCourses = '/explore/courses',
  ExplorePathways = '/explore/pathways',
  ExploreBooks = '/explore/books',
  ExploreInsights = '/explore/insights',
  ExploreTechniques = '/explore/techniques',
  ExploreTools = '/explore/tools',
  AccountSubscriptionAndBilling = '/account/subscription-and-billing',
  AccountPersonalInformation = '/account/personal-information',
  AccountPrivacy = '/account/privacy',
  AccountPassword = '/account/password',
  SummativeAssessment = '/assessment-quiz',
  Admin = '/admin',
  PersonalizeLearningJourneyJobsToBeDone = '/personalize-learning-journey/jobs-to-be-done',
  PersonalizeLearningJourneyLearningGoal = '/personalize-learning-journey/learning-goal',
  PersonalizeLearningJourneyLearningApproach = '/personalize-learning-journey/learning-approach',
  PersonalizeLearningJourneySelectRecommendedContent = '/personalize-learning-journey/select-recommended-content',
  PersonalizeLearningJourneyOrderContent = '/personalize-learning-journey/order-content',
  PersonalizeLearningJourneyCompletion = '/personalize-learning-journey/completion',
}
